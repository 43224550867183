import React from 'react';
import '../../App.css';
import './FailedToLoad.css'

function FailedToLoad() {


    return (
        <>
            <div>
                <br/>
                <h4>Redirecting to Apple App Store.</h4>
            </div>
        </>
    );
}

export default FailedToLoad;
