export const MenuItems = [
  {
    title: 'Morse Decoded',
    path: '/morse',
    cName: 'dropdown-link'
  },
  {
    title: 'Braille Decoded',
    path: '/braille',
    cName: 'dropdown-link'
  }
  // ,
  // {
  //   title: 'morse decoded',
  //   path: '/morse',
  //   cName: 'dropdown-link-morse'
  // },
  // {
  //   title: 'Braille',
  //   path: '/braille',
  //   cName: 'dropdown-link-braille'
  // }
];
