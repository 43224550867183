import React from 'react';
import '../../../App.css';
import './Home.css'

function Features(props) {

    return (
        <>
            {props.name}
            Nothing here srry.
        </>
    );
}

export default Features;
