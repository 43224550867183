import React from 'react';
import './Info.css'

function InfoPage() {
    return (
        <>
            <div className="info">
                <h2>Henhen1227</h2>
                <p className={'caption'}>Kinda weird name isn't it?</p>

                <h3>Who am I?</h3>

            </div>
        </>
    )
}

export default InfoPage;
