import React from 'react';
import '../../../App.css';


function Home() {
  return (
    <>
      <div style={{textAlign: 'center'}}>
        <br/>
        <h1>Support</h1>
        <p>For any help or questions, please contact support@henhen1227.com</p>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        </div>
    </>
  );
}

export default Home;
