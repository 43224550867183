import React from 'react';
import '../../App.css';
import './Loading.css'

function Loading() {


    return (
        <>
            <div>
                <br/>
                <h4>Loading</h4>
            </div>
        </>
    );
}

export default Loading;
