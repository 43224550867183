import React from 'react';
import './PageNotFound.css'
  
function PageNotFound() {
    return (
        <>
            <div className="pageNotFound">
                <h1>Hmm...</h1>
                <h2>404 Error</h2>
            </div>
        </>
    )
}
  
export default PageNotFound;