import React from 'react';
import '../../../App.css';
import './Play.css'


function Play() {
    return (
        <div className='tactico-play-container'>
            <div className='tactico-game-container'>
                <div>

                </div>
            </div>
        </div>
    );
}

export default Play;
